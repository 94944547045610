import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import im1 from "../../img/mm.jpg";
import "./IntroHome.css";
import { Fade } from "react-awesome-reveal";
function IntroHome() {
  return (
    <div className="ss" id="home">
      <div className="overlay"></div>
      <img className="d-block" src={im1} alt="First slide" />
      <div className="slider-caption">
        <Fade direction="up" triggerOnce="false">
          <h1
            className="mb-1"
            style={{ padding: "10px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            Haushaltshilfen von der Krankenkasse bezahlt - jetzt verfügbar ❤
          </h1>
        </Fade> 
        <Fade direction="up" triggerOnce="false"> 
          <h1 
            className="mb-1" 
            style={{ padding: "10px", backgroundColor: "rgba(0, 0, 0, 0.5)" ,width:"fit-content"}}>
            Wir freuen uns auf Sie! 😊

          </h1>
        </Fade>
      </div>
    </div>
  );
}

export default IntroHome;
