import React from "react";
import "./Datenschutz.css";
const Datenschutz = () => {
  return (
    <div className="Datenschutz container">
      <h1 className="text-center">Datenschutz</h1>
      <ul>
        <li>
          <h3>1. Einleitung</h3>
          Wir freuen uns über Ihr Interesse an unserer Website Services-mba. Der
          Schutz Ihrer persönlichen Daten ist uns sehr wichtig. In dieser
          Datenschutzerklärung informieren wir Sie darüber, welche Daten wir
          erheben, wie wir sie verwenden und welche Rechte Sie in Bezug auf Ihre
          Daten haben.
        </li>
        <li>
          <h3>2. Verantwortliche Stelle</h3>
          Verantwortlich für die Datenverarbeitung auf dieser Website ist: [Dein
          Name oder Firmenname] [Deine Adresse] [Deine E-Mail-Adresse] [Deine
          Telefonnummer]
        </li>
        <li>
          <h3>3. Erhebung und Verarbeitung von Daten</h3>
          Wir erheben und verarbeiten personenbezogene Daten, wenn Sie unsere
          Website besuchen, sich registrieren oder mit uns in Kontakt treten. Zu
          den gesammelten Daten gehören: - Name - E-Mail-Adresse - Telefonnummer
          - IP-Adresse - Nutzungsdaten (z. B. besuchte Seiten, Verweildauer)
        </li>
        <li>
          <h3>4. Zweck der Datenverarbeitung</h3>
          Die Verarbeitung Ihrer Daten erfolgt zu folgenden Zwecken: -
          Bereitstellung und Verbesserung unserer Dienstleistungen -
          Kommunikation mit Ihnen - Beantwortung von Anfragen - Versand von
          Newslettern (sofern Sie sich angemeldet haben)
        </li>
        <li>
          <h3>5. Cookies</h3>
          Unsere Website verwendet Cookies, um die Benutzererfahrung zu
          verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät
          gespeichert werden. Sie können die Verwendung von Cookies in den
          Einstellungen Ihres Browsers deaktivieren, beachten Sie jedoch, dass
          dies die Funktionalität der Website beeinträchtigen kann.
        </li>
        <li>
          <h3>6. Weitergabe von Daten</h3>
          Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei
          denn, dies ist zur Erfüllung unserer Dienstleistungen erforderlich
          oder gesetzlich vorgeschrieben.
        </li>
        <li>
          <h3>7. Ihre Rechte</h3>
          Sie haben das Recht, Auskunft über die von uns gespeicherten
          personenbezogenen Daten zu verlangen, diese zu berichtigen oder zu
          löschen. Darüber hinaus haben Sie das Recht, der Verarbeitung Ihrer
          Daten zu widersprechen und die Einschränkung der Verarbeitung zu
          verlangen.
        </li>
        <li>
          <h3>8. Änderungen der Datenschutzerklärung</h3>
          Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
          zu ändern. Die aktuelle Version wird auf unserer Website
          veröffentlicht.
        </li>
        <li>
          <h3>9. Kontakt</h3>
          Wenn Sie Fragen zu dieser Datenschutzerklärung oder zur
        </li>
      </ul>
    </div>
  );
};

export default Datenschutz;
