import React, { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./navbar.css";
import { Link } from "react-router-dom";
const Navbarr = () => {
  const activeLink = () => {
    const link = document.querySelectorAll(".navbar-nav .nav-link");
    link.forEach((act) => {
      act.addEventListener("click", function () {
        link.forEach((btn) => btn.classList.remove("active"));
        this.classList.add("active");
      });
    });
  };
  useEffect(() => {
    // scrollToElement()
    activeLink();
  }, []);
  return (
    <div style={{ backgroundColor: "white" }} className="mainnav">
      <div className="firstnav ">
        <div>
          <a
            style={{ color: "#fff", textDecoration: "none" }}
            href="https://wa.me/+4915144947258">
            <i style={{ color: "#fff" }} className="fa fa-phone"></i>
            +4915144947258
          </a>
        </div>
        <div>
          <a
            style={{ color: "#fff", textDecoration: "none" }}
            href="mailto:Info@services-mba.de">
            <i className="fa-regular fa-envelope"> </i>
            Info@services-mba.de
          </a>
        </div>
        {/* <div>
          <a
            style={{ color: "#fff", textDecoration: "none" }}
            href="https://www.facebook.com/share/g4gytrfKrwac37CQ/">
            <i
              style={{ color: "#fff" }}
              className="fa fa-brands fa-facebook"></i>
            Facebook
          </a>
        </div> */}
      </div>{" "}
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Brand href="/">
            <img src="logo.png" alt="" />
          </Navbar.Brand>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className="active" as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/Leistungen">
                Leistungen
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/Überuns">
                Über uns
              </Nav.Link> */}

              {/* <NavDropdown title="Leistungen" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
              {/* <NavDropdown title="Über uns" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link as={Link} to="/Überuns">
                Über uns
              </Nav.Link>
              <Nav.Link as={Link} to="/Kontakt">
                Kontakt
              </Nav.Link>
              <Nav.Link as={Link} to="/Terminvereinbaren">
                Termin vereinbaren
              </Nav.Link>
              <Nav.Link as={Link} to="/Datenschutz">
                Datenschutz
              </Nav.Link>
              {/* <NavDropdown title="Referenzen" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navbarr;
