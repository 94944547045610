import React, { useState } from "react";
import "./SomeLink.css";
import img1 from "../../img/home.png";
import img2 from "../../img/window.png";
import img3 from "../../img/power-washing.png";
import img4 from "../../img/residential.png";
import img5 from "../../img/upholstery.png";
import img6 from "../../img/wash.png";
import img7 from "../../img/stain-remover.png";
import img8 from "../../img/cleaning.png";
import img9 from "../../img/dove.png";
import img10 from "../../img/buffet.png";
import img11 from "../../img/brickwall.png";
import img12 from "../../img/glass-cleaning.png";
import img13 from "../../img/magic-wand.png";
import img14 from "../../img/fire.png";
import img15 from "../../img/custom-clearance.png";
import img16 from "../../img/wooden-brush.png";
import img17 from "../../img/leak.png";
import img18 from "../../img/dig-through.png";
import img19 from "../../img/cleaning-spray.png";
import img20 from "../../img/social-care.png";
import img21 from "../../img/medical.png";
import img22 from "../../img/accident.png";
import img1pop from "../../img/Fensterreinigung.jpg";

import img2pop from "../../img/Des.jpg";
import img3pop from "../../img/Operation Unfall.jpg";
import img4pop from "../../img/Pflegebedürftige.jpg";
import img5pop from "../../img/Wohnungsreinigung.jpg";
import img6pop from "../../img/Schwan.jpg";
import img7pop from "../../img/Polsterreinigung.jpg";

import MainModal from "../Modal/Modal";

const SomeLink = () => {
  const [show, setShow] = useState(false);
  const [Content, setContent] = useState(false);

  const [imgsrc, setimgsrc] = useState("");
  const handleClose = () => {
    setContent("");
    setimgsrc("");
    setShow(false);
  };
  const handleShow = (content, imgsr) => {
    setContent(content);
    setimgsrc(imgsr);
    setShow(true);
  };
  return (
    <div className="SomeLink">
      <h2>Weitere Leistungen</h2>
      <div
        className="SomeConent"
        onClick={() =>
          handleShow(
            `1. Individuelle Pflegeplanung: Wir erstellen einen maßgeschneiderten Pflegeplan,
der auf die spezifischen Bedürfnisse und Wünsche jedes pflegebedürftigen Kunden abgestimmt ist.
2. Umfassende Betreuung: Wir bieten nicht nur körperliche Pflege, sondern auch emotionale Unterstützung und soziale Interaktion, um das Wohlbefinden unserer Kunden zu fördern.
3. Häusliche Pflege: Unsere Dienstleistungen können in der gewohnten Umgebung der Kunden erbracht werden, was den Komfort und die Lebensqualität erhöht.
4. Flexibilität: Wir bieten flexible Pflegezeiten an, die sich an den Bedürfnissen der Kunden orientieren, sei es stundenweise, täglich oder rund um die Uhr.
5. Zusammenarbeit mit Angehörigen: Wir beziehen die Angehörigen in den Pflegeprozess ein und informieren sie regelmäßig über den Fortschritt und die Bedürfnisse des Pflegebedürftigen.
6. Zusätzliche Dienstleistungen: Neben der Grundpflege bieten wir auch Unterstützung bei der Haushaltsführung, der Medikamentenverwaltung und der Organisation von Arztbesuchen an.
7. Qualitätssicherung: Wir legen großen Wert auf die Qualität unserer Dienstleistungen und führen regelmäßige Schulungen und Feedbackgespräche durch, um sicherzustellen, dass unsere Pflegekräfte stets auf dem neuesten Stand sind.
Wenn Sie weitere spezifische Informationen oder Details zu bestimmten Leistungen benötigen, lassen Sie es mich wissen!`,
            img4pop
          )
        }>
        <div>
          <img src={img20} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Pflegebedürftige</h6>
      </div>
      <div
        className="SomeConent"
        onClick={() =>
          handleShow(
            `In dieser besonderen Zeit biete ich Unterstützung in verschiedenen Bereichen an, um Ihnen den Alltag zu erleichtern. Dazu gehören:
1. Haushaltsführung: Ich helfe Ihnen bei der Reinigung und Pflege Ihres Haushalts, damit Sie sich auf Ihre Gesundheit und Ihr Baby konzentrieren können.
2. Einkäufe und Besorgungen: Ich übernehme Einkäufe für den täglichen Bedarf, damit Sie sich nicht um diese Aufgaben kümmern müssen.
3. Kochen: Ich bereite gesunde Mahlzeiten zu, die auf Ihre Bedürfnisse abgestimmt sind, um sicherzustellen, dass Sie die richtige Ernährung erhalten.
4. Betreuung von Geschwisterkindern: Falls Sie bereits Kinder haben, kann ich auch deren Betreuung übernehmen, damit Sie Zeit für sich und das Neugeborene haben.
5. Unterstützung bei der Organisation: Ich helfe Ihnen, den Alltag zu organisieren, sei es durch das Erstellen von To-Do-Listen oder das Planen von Terminen.
6. Emotionale Unterstützung: Ich bin auch da, um Ihnen zuzuhören und Sie in dieser emotionalen Zeit zu unterstützen.
Mein Ziel ist es, Ihnen in dieser wichtigen Lebensphase zur Seite zu stehen, damit Sie sich auf das Wesentliche konzentrieren können: Ihre Familie und Ihr Wohlbefinden. Wenn Sie weitere Fragen haben oder spezifische Wünsche, lassen Sie es mich gerne wissen!`,
            img6pop
          )
        }>
        <div>
          <img src={img21} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Schwangerschaft / Entbindung</h6>
      </div>
      <div
        className="SomeConent"
        onClick={() =>
          handleShow(
            `1. Haushaltsführung: Ich übernehme die Reinigung und Pflege Ihres Haushalts, damit Sie sich nicht um diese Aufgaben kümmern müssen und sich auf Ihre Erholung konzentrieren können.
2. Einkäufe und Besorgungen: Ich erledige Einkäufe für Sie, sei es für Lebensmittel oder andere notwendige Dinge, damit Sie nicht das Haus verlassen müssen.
3. Kochen: Ich bereite gesunde und nahrhafte Mahlzeiten für Sie zu, die auf Ihre speziellen Bedürfnisse abgestimmt sind, um Ihre Genesung zu unterstützen.
4. Unterstützung im Alltag: Ich helfe Ihnen bei alltäglichen Aufgaben, wie z.B. Wäsche waschen, Aufräumen oder anderen Tätigkeiten, die Ihnen momentan schwerfallen könnten.
5. Begleitung zu Terminen: Falls nötig, kann ich Sie zu Arztterminen oder Therapiesitzungen begleiten, um sicherzustellen, dass Sie die notwendige Unterstützung haben.
6. Emotionale Unterstützung: Ich bin auch da, um Ihnen zuzuhören und Sie in dieser Zeit emotional zu unterstützen, da eine Genesung oft auch eine mentale Herausforderung sein kann.
7. Betreuung von Angehörigen: Wenn Sie Kinder oder andere Angehörige haben, kann ich auch deren Betreuung übernehmen, damit Sie sich auf Ihre eigene Genesung konzentrieren können.
Mein Ziel ist es, Ihnen in dieser herausfordernden Zeit zur Seite zu stehen und Ihnen zu helfen, so schnell wie möglich wieder auf die Beine zu kommen. Wenn Sie spezielle Wünsche oder Fragen haben, lassen Sie es mich bitte wissen!`,
            img3pop
          )
        }>
        <div>
          <img src={img22} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Operation / Unfall</h6>
      </div>
      <div
        className="SomeConent"
        onClick={() =>
          handleShow(
            `1. Gründliche Reinigung: Ich übernehme die allgemeine Reinigung Ihrer Wohnung, einschließlich Staubsaugen, Wischen, Staubwischen und Oberflächenreinigung.
 2. Küchenreinigung: Dazu gehört das Reinigen von Arbeitsflächen, Spüle, Herd und Küchenschränken sowie das Entleeren des Mülls und das Reinigen von Geräten wie Mikrowelle und Kühlschrank.
 3. Badezimmerreinigung: Ich sorge dafür, dass Ihr Badezimmer hygienisch sauber ist, indem ich Waschbecken, Toilette, Dusche oder Badewanne gründlich reinige und desinfiziere.
 4. Wohnräume: Ich kümmere mich um die Reinigung der Wohnräume, einschließlich der Pflege von Möbeln, Teppichen und anderen Oberflächen.
 5. Schlafzimmer: Ich sorge für eine saubere und ordentliche Umgebung, indem ich Betten mache, Staub wische und den Boden reinige.
 6. Fensterreinigung: Auf Wunsch kann ich auch die Fenster reinigen, um für einen klaren Ausblick und mehr Licht in Ihrer Wohnung zu sorgen.
 7. Sonderwünsche: Wenn Sie spezielle Anforderungen oder Wünsche haben, wie z.B. die Reinigung von bestimmten Bereichen oder das Organisieren von Räumen, lassen Sie es mich einfach wissen.
 Ich passe meine Dienstleistungen gerne an Ihre individuellen Bedürfnisse an, um sicherzustellen, dass Sie mit dem Ergebnis zufrieden sind.  Wenn Sie Fragen haben oder einen Termin vereinbaren möchten, stehe ich Ihnen jederzeit zur Verfügung!
 `,
            img5pop
          )
        }>
        <div>
          <img src={img1} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Wohnungsreinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(
            `Unsere Haushaltshilfe bietet Ihnen einen professionellen Fensterreinigungsservice, der dafür sorgt, dass Ihre Fenster strahlend sauber sind. Dies umfasst:
Vorbereitung: Abdecken von Möbeln und Böden, um Schäden zu vermeiden.
Reinigung: Gründliches Reinigen der Fensterinnenseiten und -außenseiten mit speziellen Reinigungsmitteln und Werkzeugen.
Nachbearbeitung: Überprüfung auf Streifen oder Rückstände und gegebenenfalls Nachreinigung.`,
            img1pop
          )
        }>
        <div>
          <img src={img2} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Fensterreinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Unsere Teppichreinigung sorgt dafür, dass Ihre Teppiche wieder frisch und sauber aussehen. Der Prozess umfasst:
Vorbereitung: Entfernen von Möbeln und Staub durch gründliches Absaugen.
Reinigung: Anwendung von speziellen Reinigungsmitteln und -methoden (z.B. Dampfreinigung oder chemische Reinigung), um Schmutz, Flecken und Gerüche zu entfernen.
Trocknung: Sicherstellen, dass der Teppich schnell trocknet, um Schimmelbildung zu vermeiden.
Genießen Sie einen sauberen und hygienischen Teppich, der das Ambiente Ihres Raumes verbessert!`)
        }>
        <div>
          <img src={img3} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Teppichreinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(
            `Unsere Polsterreinigung sorgt dafür, dass Ihre Möbel wieder frisch und einladend aussehen. Der Prozess umfasst:
Vorbereitung: Entfernen von Staub und Schmutz durch gründliches Absaugen der Polster.
Reinigung: Anwendung von speziellen Reinigungsmitteln und -techniken (z.B. Dampfreinigung oder Sprühextraktion), um Flecken, Gerüche und Allergene zu entfernen.
Trocknung: Sicherstellen, dass die Polster schnell trocknen, um die Bildung von Schimmel zu verhindern.
Genießen Sie saubere und hygienische Polstermöbel, die den Komfort Ihres Wohnraums erhöhen!`,
            img7pop
          )
        }>
        <div>
          <img src={img5} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Polsterreinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img4} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Baureinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img7} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Umzugsreinigung</h6>
      </div>
      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img6} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Endreinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img8} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Frühjahrsputz</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img9} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Taubenkot entfernen</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img10} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Gastronomiereinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img11} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Stark verschmutzte Wohnung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img3} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Teppichwäscherei</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img12} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Wintergarten reinigen</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img13} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Nikotingeruch entfernen</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img14} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Brandreinigung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img15} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Entrümpelung</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img16} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Holzterrasse reinigen</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img17} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Wasserschaden</h6>
      </div>

      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img18} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Messie Wohnung</h6>
      </div>
      <div
        className="SomeConent"
        onClick={() =>
          handleShow(`Dieser Service wird in Kürze verfügbar sein`, img2pop)
        }>
        <div>
          <img src={img19} alt="" style={{ opacity: "0.6" }} />
        </div>
        <h6 className="text-center">Desinfizierende Reinigung</h6>
      </div>

      {/* <MainModal
        Content={Content}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        setShow={setShow}
      /> */}
      <MainModal
        Content={Content}
        handleClose={handleClose}
        imgsrc={imgsrc}
        handleShow={handleShow}
        show={show}
        setShow={setShow}
      />
    </div>
  );
};

export default SomeLink;
