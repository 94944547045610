import React from "react";
import { Fade } from "react-awesome-reveal";
import im1 from "../../img/How to Start a Career as a Commercial Cleaner (5).webp";
import im2 from "../../img/2.png";
import im3 from "../../img/Screenshot 2024-09-03 214621.png";
import Intro from "../intro/intro";
const AboutPage = () => {
  return (
    <div>
   <Intro />
      <hr className="container" />
      <div className="heightsection">
        <section className="info">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <Fade direction="left" triggerOnce="false">
                  <h2 className="text-uppercase">Services-mba</h2>
                </Fade>
                <Fade direction="left" triggerOnce="false">
                  <p>
                    Momentan können wir leider keine Verordnungen mit
                    Pflegestufe durch den Entlastungsbeitrag durchführen. Unser
                    Angebot richtet sich lediglich an Patienten mit akuten
                    Verordnungen (Risikoschwangerschaft, Kaiserschnitte,
                    Krebserkrankungen, Rehabilitation nach Operationen).
                  </p>
                </Fade>
              </div>
              <div className="col-md-6">
                <Fade direction="right" triggerOnce="false">
                  <img src={im1} className="img-responsive" alt="feature img" />
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </div>
      <hr className="container" />

      <div className="heightsection">
        <section className="info">
          <div className="container">
            <div className="row">
            
              <div className="col-md-6 ">
                <Fade direction="right" triggerOnce="false">
                  <h2 className="text-uppercase">Services-mba</h2>
                </Fade>
                <Fade direction="right" triggerOnce="false">
                  <p>
                    ✔ Nach einem Unfall oder einer Operation wie z.b. einer
                    Knie-OP ✔ Bei einer Krebserkrankung
                    ✔Schwangerschaftsbedingte Herausforderungen - Bettruhe,
                    Risiko Frühgeburt, medizinische Gründe wie Kaiserschnitt
                    ✔Dann haben Sie Anspruch auf Unterstützung im Haushalt!
                  </p>
                </Fade>
              </div>
              <div className="col-md-6">
                {/* <video
                style={{ width: "400px" }}
                loop
                autoPlay
                muted
                className="vid">
                <source src={v} />
              </video> */}
                <Fade direction="left" triggerOnce="false">
                  <img src={im2} className="img-responsive" alt="feature img" />
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </div>
      <hr className="container" />
      <div className="heightsection">
        <section className="info">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <Fade direction="left" triggerOnce="false">
                  <h2 className="text-uppercase">Services-mba</h2>
                </Fade>
                <Fade direction="left" triggerOnce="false">
                  <p>
                    ✔ Haushaltsarbeiten, Einkaufen, Kochen. ✔ Betreuung von
                    Geschwisterkindern. ✔ Aufräumen, Wäsche, Spülen. ✔
                    Besorgungen (Apotheke, Paketdienst). ✔ Begleitung zu
                    Arztbesuchen.
                  </p>
                </Fade>
              </div>
              <div className="col-md-6">
                {/* <video
                style={{ width: "400px" }}
                loop
                autoPlay
                muted
                className="vid">
                <source src={v} />
              </video> */}
                <Fade direction="right" triggerOnce="false">
                  <img src={im3} className="img-responsive" alt="feature img" />
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutPage;
