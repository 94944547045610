import React, { useEffect } from "react";
import v1 from "../../img/WhatsApp Video 2024-09-14 at 18.39.05_bbd83456.mp4";
import "./video.css";
const Video = () => {
  const PlayVideo = () => {
    const link = document.querySelector(".vid");
    link.removeAttribute("controls");
    link.play();
  };
  useEffect(() => {
    PlayVideo();
  }, []);
  return (
    <>
      <div className="mainvideo">
        {" "}
        <div className="overlay"></div>
        <h4
          className="text-center"
          style={{ fontWeight: "bold", color: " #5CB3FF" }}>
          Präsentation
        </h4>
        <h1
          className="text-center"
          style={{ fontWeight: "bold", color: "#fff" }}>
          Wir setzen uns dafür ein, dass jeder Ort strahlend und organisiert ist
        </h1>
        <video loop autoPlay={true} muted className="vid">
          <source src={v1} />
        </video>
      </div>
    </>
  );
};

export default Video;
