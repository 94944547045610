import React from "react";
import "./tabs.css";
import img1 from "../../img/home.png";
import img2 from "../../img/window.png";
import img3 from "../../img/power-washing.png";
import img4 from "../../img/upholstery.png";
const Tabs = () => {
  return (
    <div className="main-tabs">
      <div class="tabs">
        <input type="radio" name="tabs" id="tabone" checked />
        <label for="tabone">
          <img src={img1} alt="" width="35" /> Notfallreinigung
        </label>
        <div class="tab">
          <h1>Services-mba</h1>
          <p> ✔️ Zum Wunschtermin</p>
          <p> ✔️ Einmalige & kurzfristig</p>
          <p> ✔️ Wir stellen das Material</p>
          <p> ✔️ Keine Anfahrtskosten</p>
          <p> ✔️ Inkl. aller Kosten</p>
        </div>

        <input type="radio" name="tabs" id="tabtwo" checked />
        <label for="tabtwo">
          <img src={img2} alt="" width="35" /> Gebäudehygiene
        </label>
        <div class="tab">
          <h1>Services-mba</h1>
          <p> ✔️ Zum Wunschtermin</p>
          <p> ✔️ Einmalige & kurzfristig</p>
          <p> ✔️ Wir stellen das Material</p>
          <p> ✔️ Keine Anfahrtskosten</p>
          <p> ✔️ Inkl. aller Kosten</p>
        </div>

        <input type="radio" name="tabs" id="tabthree" checked />
        <label for="tabthree">
          <img src={img3} alt="" width="35" /> DIENSTLEISTUNGEN
        </label>
        <div class="tab">
          <h1>Services-mba</h1>
          <p> ✔️ Zum Wunschtermin</p>
          <p> ✔️ Einmalige & kurzfristig</p>
          <p> ✔️ Wir stellen das Material</p>
          <p> ✔️ Keine Anfahrtskosten</p>
          <p> ✔️ Inkl. aller Kosten</p>
        </div>

        <input type="radio" name="tabs" id="tabfoue" checked />
        <label for="tabfoue">
          <img src={img4} alt="" width="35" /> Außenreinigung
        </label>
        <div class="tab">
          <h1>Services-mba</h1>
          <p> ✔️ Zum Wunschtermin</p>
          <p> ✔️ Einmalige & kurzfristig</p>
          <p> ✔️ Wir stellen das Material</p>
          <p> ✔️ Keine Anfahrtskosten</p>
          <p> ✔️ Inkl. aller Kosten</p>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
