import React from "react";
import "./help.css";
import img from "../../img/home.jpg";
import { Fade } from "react-awesome-reveal";

const Help = () => {
  return (
    <div id="help" className="help">
      <div className="heightsection">
        <section className="info">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img src={img} className="img-responsive" alt="feature img" />
              </div>
              <div className="col-md-6 contentt">
                <Fade direction="left" triggerOnce="false">
                  <h2 className="text-uppercase">Rechtlicher Anspruch</h2>
                  <p>
                    <ul>
                      <li>
                        ✔ Nach einem Unfall oder einer Operation wie z.b. einer
                        Knie-OP
                      </li>
                      <li>✔ Bei einer Krebserkrankung</li>
                      <li>
                        ✔ Schwangerschaftsbedingte Herausforderungen - Bettruhe,
                        Risiko Frühgeburt, medizinische Gründe wie Kaiserschnitt
                      </li>
                      <li>
                        ✔ Dann haben Sie Anspruch auf Unterstützung im Haushalt!
                      </li>
                    </ul>
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Help;
