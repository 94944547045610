import React from "react";
import "./services.css";
import img1 from "../../img/Clean-2.webp";
import img2 from "../../img/Clean-1.webp";
import img3 from "../../img/Clean-3.webp";
import img4 from "../../img/Clean-4.webp";

const Services = () => {
  return (
    <div className="serv">
      {/* <h3
        className="text-center"
        style={{ color: "#071c43", fontWeight: "bold" }}>
        Freie Stellen
      </h3> */}
      {/* <p className="text-center">
        Lust auf Handwerk? Begeistert von moderner Gebäudetechnik? Gerne
        partnerschaftlich in einem starken Team arbeiten?
      </p> */}
      <div id="service" className="heightsection service">
        <div className="cardd">
          <img src={img1} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            <h5 style={{ fontWeight: "bold" }}>Planen Sie eine Reinigung</h5>
            <p>
              Unsere Experten stehen bereit, um Ihre Bedürfnisse zu erfüllen.
              Kontaktieren Sie uns noch heute
            </p>
          </div>
        </div>
        <div className="cardd">
          <img src={img2} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            <h5 style={{ fontWeight: "bold" }}>Reinigungsplan</h5>
            <p>
              Als Reinigungsexperten planen wir vor der Arbeit, um höchste
              Sauberkeit zu erreichen
            </p>
          </div>
        </div>
        <div className="cardd">
          <img src={img3} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            <h5 style={{ fontWeight: "bold" }}>Qualitätsprüfung</h5>
            <p>
              Wir führen regelmäßige Qualitätsprüfungen durch, um
              sicherzustellen, dass unsere Reinigungsstandards stets höchsten
            </p>
          </div>
        </div>
        <div className="cardd">
          <img src={img4} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            <h5 style={{ fontWeight: "bold" }}>
              Genießen Sie einen sauberen Raum
            </h5>
            <p>
              Wir führen regelmäßige Qualitätsprüfungen durch, um
              sicherzustellen, dass unsere Reinigungsstandards stets höchsten
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
