import React, { useState } from "react";
import "./contact.css";
import { Spinner } from "react-bootstrap";
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [Loading, setLoading] = useState(false);
  //   const notify = (msg, type) => {
  //     if (type === "warn")
  //         toast.warn(msg)
  //     else if (type ==="success")
  //         toast.success(msg)
  //     else if (type ==="error")
  //         toast.error(msg)
  // };
  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   if(name===""||email===""||subject===""||message===""){
  //     notify('من فضلك اكمل البيانات المطلوبة','error')
  //     return
  //   }
  //   setLoading(true);
  //   emailjs
  //     .send(
  //       "service_miry0e7",
  //       "template_wmzhupc",
  //       {
  //         name:name,
  //         email:email,
  //         subject:subject,
  //         message:message,
  //       },
  //       "XvO8s0zbcgmjO7eMH"
  //     )
  //     .then(
  //       (result) => {
  //         setLoading(false);
  //         notify("تم إرسال استفساركم بنجاح", "success");
  //         setName("");
  //         setemail("");
  //         setmessage("");
  //         setsubject("");
  //       },
  //       (error) => {
  //         setLoading(false);
  //         setName("");
  //         setemail("");
  //         setmessage("");
  //         setsubject("");
  //         notify("فشل إرسال استفساركم ", "error");
  //       }
  //     );
  // };
  return (
    <>
      <h2 className="text-center m-4">Kontakt</h2>
      <section id="contact" className="m-4">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <h2 className="text-uppercase">Kontaktieren Sie uns</h2>
                <p>
                  Jetzt kontaktieren für eine kostenlose Beratung! WhatsApp und
                  Tel. +4915144947258
                </p>
                <p>
                  Unsere Mitarbeiter sind hauptsächlich in Niedersachsen
                  beschäftigt. Falls Sie an einem anderen Ort wohnen, können wir
                  Ihnen gegebenenfalls auch helfen - fragen Sie ruhig einfach
                  an.
                </p>
                <address>
                  <a href="https://wa.me/+4915144947258">
                    <i style={{ color: "#fff" }} className="fa fa-phone"></i>
                  </a>
                  <a href="https://www.facebook.com/share/g4gytrfKrwac37CQ/">
                    <i
                      style={{ color: "#fff" }}
                      className="fa fa-brands fa-facebook"></i>
                  </a>
                </address>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div className="contact-form">
                  <form action="#" method="post">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="der Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        required
                        className="form-control"
                        value={email}
                        placeholder="E-Mail"
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="das Thema"
                        value={subject}
                        required
                        onChange={(e) => setsubject(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        placeholder="Nachricht"
                        required
                        value={message}
                        rows="4"
                        onChange={(e) => setmessage(e.target.value)}></textarea>
                    </div>
                    <div className="col-md-8">
                      {Loading ? (
                        <button className="buttcontact">
                          <Spinner />
                        </button>
                      ) : (
                        <button className="buttcontact">Schicken</button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
