import React, { useEffect, useState } from "react";
import Intro from "../intro/intro";
import Help from "../help/help";
import IntroHome from "../IntroHome/IntroHome";
import Services from "../services/services";
import img1 from "../../img/home.png";
import img2 from "../../img/window.png";
import img3 from "../../img/power-washing.png";
import img4 from "../../img/residential.png";
import img5 from "../../img/upholstery.png";
import img7 from "../../img/stain-remover.png";
import img1pop from "../../img/Wohnungsreinigung.jpg";
import img2pop from "../../img/Fensterreinigung.jpg";
import img3pop from "../../img/Polsterreinigung.jpg";

import Tabs from "../tabs/tabs";
import MainModal from "../Modal/Modal";
import Terminanfrage from "../Terminanfrage/Terminanfrage";
import Video from "../video/video";
const HomePage = () => {
  const [show, setShow] = useState(false);
  const [Content, setContent] = useState(false);
  const [imgsrc, setimgsrc] = useState("");
  const handleClose = () => {
    setContent("");
    setimgsrc("");
    setShow(false);
  };
  const handleShow = (content, imgsr) => {
    setContent(content);
    setimgsrc(imgsr);
    setShow(true);
  };

  return (
    <>
      <IntroHome />
      <Tabs />
      <div className="SomeLink">
        <h2>Weitere Leistungen</h2>
        <div
          className="SomeConent"
          onClick={() =>
            handleShow(
              `1.d Gründliched Reinigung:d Ichd übernehmed died allgemeined Reinigungd Ihrerd Wohnung 
d einschließlichd Staubsaugen,d Wischen,d Staubwischend undd Oberflächenreinigung.
 2.d Küchenreinigung:d Dazud gehörtd dasd Reinigend vond Arbeitsflächen,d Spüle,d Herdd undd 
 Küchenschränkend sowied dasd Entleerend desd Müllsd undd dasd Reinigend vond Gerätend wied Mikrowelled 
 undd Kühlschrank.
 3.d Badezimmerreinigung:d Ichd sorged dafür,d dassd Ihrd Badezimmerd hygienischd
  sauberd ist,d indemd ichd Waschbecken,d Toilette,d Dusched oderd Badewanned gründlichd 
  reiniged undd desinfiziere.
 4.d Wohnräume:d Ichd kümmered michd umd died Reinigungd derd Wohnräume,d einschließlichd
  derd Pfleged vond Möbeln,d Teppichend undd anderend Oberflächen.
 5.d Schlafzimmer:d Ichd sorged fürd eined saubered undd ordentliched Umgebung,d 
 indemd ichd Bettend mache,d Staubd wisched undd dend Bodend reinige.
 6.d Fensterreinigung:d Aufd Wunschd kannd ichd auchd died Fensterd reinigen,
 d umd fürd einend klarend Ausblickd undd mehrd Lichtd ind Ihrerd Wohnungd zud sorgen.
 7.d Sonderwünsche:d Wennd Sied spezielled Anforderungend oderd Wünsched
  haben,d wied z.B.d died Reinigungd vond bestimmtend Bereichend oderd dasd Organisierend 
  vond Räumen,d lassend Sied esd michd einfachd wissen.
 Ichd passed meined Dienstleistungend gerned and Ihred individuellend Bedürfnissed
  an,d umd sicherzustellen,d dassd Sied mitd demd Ergebnisd zufriedend sind.d  Wennd Sied
   Fragend habend oderd einend Termind vereinbarend möchten,d stehed ichd Ihnend jederzeitd 
   zurd Verfügung!`,
              img1pop
            )
          }>
          <div>
            <img src={img1} alt="" style={{ opacity: "0.6" }} />
          </div>
          <h6 className="text-center">Wohnungsreinigung</h6>
        </div>

        <div
          className="SomeConent"
          onClick={() =>
            handleShow(
              `Unsere Haushaltshilfe bietet Ihnen einen professionellen Fensterreinigungsservice,
der dafür sorgt, dass Ihre Fenster strahlend sauber sind. Dies umfasst:
Vorbereitung: Abdecken von Möbeln und Böden, um Schäden zu vermeiden.
Reinigung: Gründliches Reinigen der Fensterinnenseiten und -außenseiten mit speziellen Reinigungsmitteln und Werkzeugen.
Nachbearbeitung: Überprüfung auf Streifen oder Rückstände und gegebenenfallsd Nachreinigung.`,
              img2pop
            )
          }>
          <div>
            <img src={img2} alt="" style={{ opacity: "0.6" }} />
          </div>
          <h6 className="text-center">Fensterreinigung</h6>
        </div>

        <div
          className="SomeConent"
          onClick={() =>
            handleShow(`Unsere Teppichreinigung sorgt dafür, dass Ihre Teppiche wieder frisch und 
sauber aussehen. Der Prozess umfasst:
Vorbereitung: Entfernen von Möbeln und Staub durch gründliches Absaugen.
Reinigung: Anwendung von speziellen Reinigungsmitteln und -methoden (z.B. Dampfreinigung oder
chemische Reinigung), um Schmutz, Flecken und Gerüche zu entfernen.
Trocknung: Sicherstellen, dass der Teppich schnell trocknet, um Schimmelbildung zu vermeiden.
Genießen Sie einen sauberen und hygienischen Teppich, der das Ambiente Ihres Raumesd verbessert!`)
          }>
          <div>
            <img src={img3} alt="" style={{ opacity: "0.6" }} />
          </div>
          <h6 className="text-center">Teppichreinigung</h6>
        </div>

        <div
          className="SomeConent"
          onClick={() =>
            handleShow(
              `Unsere Polsterreinigung sorgt dafür, dass Ihre Möbel wieder frisch und einladend aussehen. Der Prozess umfasst:
Vorbereitung: Entfernen von Staub und Schmutz durch gründliches Absaugen der Polster.
Reinigung: Anwendung von speziellen Reinigungsmitteln und -techniken (z.B. Dampfreinigung oder Sprühextraktion)
, um Flecken, Gerüche und Allergene zu entfernen.
Trocknung: Sicherstellen, dass die Polster schnell trocknen, um die Bildung von Schimmel zu verhindern.
Genießen Sie saubere und hygienische Polstermöbel, die den Komfort Ihres Wohnraumsd erhöhen!`,
              img3pop
            )
          }>
          <div>
            <img src={img5} alt="" style={{ opacity: "0.6" }} />
          </div>
          <h6 className="text-center">Polsterreinigung</h6>
        </div>

        <div
          className="SomeConent"
          onClick={() =>
            handleShow(`Dieser Service wird in Kürze verfügbard sein`, img2pop)
          }>
          <div>
            <img src={img4} alt="" style={{ opacity: "0.6" }} />
          </div>
          <h6 className="text-center">Baureinigung</h6>
        </div>

        <div
          className="SomeConent"
          onClick={() =>
            handleShow(`Dieser Service wird in Kürze verfügbard sein`, img2pop)
          }>
          <div>
            <img src={img7} alt="" style={{ opacity: "0.6" }} />
          </div>
          <h6 className="text-center">Umzugsreinigung</h6>
        </div>
      </div>
      <Intro />
      <hr />
      {/* <ContentSection /> */}
      <Services />

      {/* <Datereq /> */}
      {/* <SomeLink /> */}
      <Help />
      {/* <Contact /> */}
      <Video />
      <MainModal
        Content={Content}
        imgsrc={imgsrc}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default HomePage;
