import React from "react";
import "./intro.css";
import { Fade } from "react-awesome-reveal";
const Intro = () => {
  // const PlayVideo = () => {
  //   const link = document.querySelector(".vid");
  //   link.addEventListener("loadeddata", () => {
  //     link.removeAttribute("controls");
  //     link.play();
  //   });
  // };
  // useEffect(() => {
  //   PlayVideo();
  // }, []);
  return (
    <div id="home" className="heightsection">
      <section className="info">
        {/* <h3 className="tit text-center">اهم المميزات </h3> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <Fade direction="left" triggerOnce="false">
                <h2 className="text-uppercase">Unsere Dienstleistungen</h2>
              </Fade>
              <Fade direction="left" triggerOnce="false">
                <p>✔ Haushaltsarbeiten, Einkaufen, Kochen.</p>
                <p>✔ Betreuung von Geschwisterkindern.</p>
                <p>✔ Aufräumen, Wäsche, Spülen.</p>
                <p>✔ Besorgungen (Apotheke, Paketdienst).</p>
                <p>✔ Begleitung zu Arztbesuchen.</p>
                <p>
                  Wir helfen beim Antrag mit den Krankenkassen und rechnen alles
                  direkt ab, sodass Sie sich auf Ihre Gesundheit konzentrieren
                  können.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
