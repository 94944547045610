import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src="logo.png" style={{ width: "150px" }} alt="" />
            <h6>Wir sind für Sie da</h6>
            <h6> Allgemeines Dienstleistungsunternehmen</h6>
          </div>
          <div className="col-md-6">
            <h2 className="mb-3">Anschrift</h2>
            <ul>
              <li>
                <a href="mailto:Info@services-mba.de">
                  <i className="fa-regular fa-envelope"> </i> E-Mail:
                  Info@services-mba.de
                </a>
              </li>
              <li>
                <a href="mailto:Info@services-mba.de">
                  <i className="fa-solid fa-location-dot"></i>
                  Lehrter str.16, 31319 Sehnde.
                </a>
              </li>
              <li>
                <a href="https://wa.me/+4915144947258">
                  <i style={{ color: "#fff" }} className="fa fa-phone"></i>{" "}
                  Telefon: +4915144947258
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <h2>Social</h2>
            <a href="https://www.facebook.com/share/g4gytrfKrwac37CQ/">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://wa.me/+4915144947258">
              <i className="fab fa-whatsapp"></i>
            </a>
            <a href="https://www.instagram.com/mba_service09?utm_source=qr&igsh=ZzMwZzFmejl2MGFu">
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://x.com/MBA_service?t=uXPcXyUvZd5K2PBv_0z64g&s=08">
              <i className="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.tiktok.com/@mba_service?_t=8piruvmMYSX&_r=1">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="text-center">
        Alle Rechte bleiben Services-mba vorbehalten &copy;
      </div>
      <a className="btn-main" href="tel:491728840205">
        anrufen{" "}
        <i
          style={{ color: "#fff", fontSize: "18px" }}
          className="fa-solid fa-phone-flip"></i>
      </a>
    </div>
  );
};

export default Footer;
