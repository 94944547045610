import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/layout";
import HomePage from "./components/HomePage/HomePage";
import LeistungenPage from "./components/LeistungenPage/LeistungenPage";
import Datenschutz from "./components/Datenschutz/Datenschutz";
import Contact from "./components/contact/contact";
import AboutPage from "./components/AboutPage/AboutPage";
import Terminanfrage from "./components/Terminanfrage/Terminanfrage";
function App() {
  return (
    <div className="App">
      <Layout>
        {/* <MainModal Content/> */}
        <Routes>
          <Route index element={<HomePage />} />
          {/* <Route path="/Referenzen" element={<Referenzen />} /> */}
          <Route path="/Leistungen" element={<LeistungenPage />} /> 
          <Route path="/Kontakt" element={<Contact  />} />
          <Route path="/Überuns" element={<AboutPage />} />
          <Route path="/Datenschutz" element={<Datenschutz />} />
          <Route path="/Terminvereinbaren" element={<Terminanfrage />} />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
